/* Slick Slider */
.slick-slider {
  display: block;
  position: relative;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;

  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  margin: 0;
  padding: 0;
  display: block;
  overflow: hidden;
  position: relative;

  &:focus {
    outline: none;
  }

  .slick-list,
  .slick-track {
    transform: translate3d(0, 0, 0);
  }

  &.dragging {
    cursor: hand;
  }

  .slick-loading & {
    background: url('../images/ajax-loader.gif') center center no-repeat;
  }
}

.slick-track {
  top: 0;
  left: 0;
  display: block;
  position: relative;

  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;

  img {
    display: block;
    width: 100%;
  }

  &.slick-loading img {
    display: none;
  }

  &.dragging img {
    pointer-events: none;
  }
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

// Arrows
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  padding: 0;
  border: none;
  outline: none;
  z-index: 1;

  &:before {
    color: inherit;
    font-family: 'icomoon';
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &.slick-disabled:before {
    opacity: 0.7;
  }
}

// Dots
.slick-dots {
  list-style: none;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  position: absolute;
  left: 0;

  li {
    position: relative;
    display: inline-block;
    margin: 4px;
    padding: 0;
    cursor: pointer;
  }

  button {
    border: 0;
    margin: 0;
    padding: 0;
    display: block;
    outline: none;
    line-height: 0px;
    font-size: 0px;
    color: transparent;
    border-radius: 50%;
    transition: opacity .2s ease-out;
  }

  button:hover,
  .slick-active button {
    opacity: 0.65;
  }
}

// Carousel Styles
.cr-carousel {
  padding: 0 15px;
  margin: 0 0 $base-space 0;

  &.slick-dotted {
    margin-bottom: $base-space * 2;
  }

  .slick-slide {
    padding: 0 8px;
  }

  .slick-prev,
  .slick-next {
    top: 0;
    font-size: 22px;
    width: 24px;
    height: 100%;

    &:before {
      top: 50%;
      left: 0;
      position: absolute;
      margin-top: -11px;
    }
  }

  .slick-prev {
    left: 0;

    &:before {
      content: '\e90c';
      left: -5px;
    }
  }

  .slick-next {
    right: 0;

    &:before {
      content: '\e90b';
      right: -5px;
    }
  }

  .slick-dots {
    top: 100%;

    button {
      width: 10px;
      height: 10px;
      background: #888;
    }

  }
}

// Slider Styles
.cr-slider {
  margin: 0 0 $base-space 0;
  overflow: hidden;

  &:hover {
    .slick-prev {
      left: 15px;
    }

    .slick-next {
      right: 15px;
    }

    .slick-dots {
      bottom: 15px;
    }
  }

  .slick-prev,
  .slick-next {
    position: absolute;
    display: block;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    padding: 0;
    border: none;
    outline: none;
    z-index: 1;
    transform: translate(0, -50%);
    transition: all 500ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

    &:before {
      color: #fff;
      font-family: 'icomoon';
      font-size: 20px;
      line-height: 1;
      text-shadow: -5px 5px 16px rgba(118,95,91,0.34);
      transition: text-shadow 0.2s ease-out;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    &:hover {
      outline: none;
      color: transparent;
      background: transparent;

      &:before {
        text-shadow: -5px 5px 16px rgba(118,95,91,0.6);
      }
    }

    &.slick-disabled:before {
      opacity: 0.7;
    }
  }

  .slick-prev {
    left: -30px;

    &:before {
      content: '\f053';
    }
  }

  .slick-next {
    right: -30px;

    &:before {
      content: '\f054';
    }
  }

  .slick-dots {
    bottom: -35px;
    transition: all 500ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

    button {
      width: 13px;
      height: 13px;
      background: #fff;
    }
  }
}

@media (min-width: $screen-sm) {
  .cr-slider {
    &:hover {
      .slick-prev {
        left: 25px;
      }

      .slick-next {
        right: 25px;
      }

      .slick-dots {
        bottom: 20px;
      }
    }

    .slick-prev,
    .slick-next {
      &:before {
        font-size: 30px;
      }
    }

    .slick-dots {
      button {
        width: 17px;
        height: 17px;
      }
    }
  }
}

// rtl
[dir='rtl'] .slick-slide {
  float: right;
}