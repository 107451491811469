/**
 * Widgets
 */

.widget {
  font-size: 0.875em;
  margin-bottom: 35px;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.widget-title {
  position: relative;
  font-size: 15px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;
}

// Widget: Lists
.widget_meta,
.widget_pages,
.widget_archive,
.widget_nav_menu,
.widget_categories,
.widget_recent_entries,
.widget_recent_comments {
  li {
    padding: 10px 0;
    border-top: 1px solid transparent;

    li {
      &:first-child {
        margin-top: 10px;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }

    ul {
      margin-left: 10px;
    }
  }

  a:hover {
      opacity: 0.7;
  }
}

.widget_meta > ul,
.widget_pages > ul,
.widget_archive > ul,
.widget_nav_menu .menu,
.widget_categories > ul,
.widget_recent_entries > ul,
.widget_recent_comments > ul {
  border-bottom: 1px solid transparent;
}

.widget_archive,
.widget_categories {
  a {
    margin-right: 7px;
  }
}

// Widget: Search
.widget_search {
  .search-again {
    position: relative;
    width: 100%;

    &:before {
      content: "\f002";
      font-family: 'icomoon';
      top: 0;
      right: 0;
      position: absolute;
      width: 50px;
      height: 50px;
      line-height: 50px;
      font-size: 17px;
      text-align: center;
      display: block;
    }
  }

  input[type="search"] {
    width: 100%;
    height: 50px;
    padding: 15px 45px 15px 15px;
    border: 0;
    line-height: normal;
    -webkit-appearance: none;

    border: none;
    box-shadow: none;
    box-sizing: border-box;
  }

  .form-submit {
    input[type="submit"] {
      top: 0;
      right: 0;
      margin: 0;
      border: 0;
      padding: 0;
      z-index: 2;
      width: 50px;
      height: 50px;
      position: absolute;
      text-indent: -9999px;
      background: transparent;

      &:hover {
        box-shadow: none;
      }
    }
  }
}

// Widget: Resent Posts
.widget_recent_entries {
  .post-date {
    display: block;
    font-size: 0.8em;
    line-height: 1.1;
  }
}

// Widget: Calendar
.widget_calendar {
  td,
  th,
  caption {
    text-align: center;
  }

  caption {
    padding: 3px 0;
    font-weight: 700;
    margin-bottom: 10px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
  }

  tbody {
    td {
      font-size: 0.9em;
      border: none;
      padding: 5px;
    }

    a {
      width: 25px;
      height: 25px;
      line-height: 25px;
      border-radius: 50%;
      display: inline-block;
    }
  }

  tfoot {
    td {
      padding: 5px;
      border-width: 1px 0;
    }

    a {
      font-weight: 700;
      font-size: 0.85em;
      text-transform: uppercase;
    }

    #prev {
      text-align: left;
    }

    #next {
      text-align: right;
    }
  }
}

// Widget: Tag Cloud
.widget_tag_cloud {
  a {
    font-size: 14px !important;
  }
}

// Widget: Custom Recent Posts
.widget_posts_entries{
  >ul>li{
    padding-bottom: 13px;
    margin-bottom: 15px;
    border-bottom: solid 1px transparent;
  }
  .post-image{
    float: left;
    margin:0 20px 5px 0;
  }
  .post-content{
    h3{
      font-size: 14px;
      line-height: 1.3;
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }
  .post-category-comment{
    clear: both;
    padding-top: 5px;
    a{
      font-size: 13px;
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
    }
  }
  .post-comments{
    border-left: solid 1px transparent;
    padding-left: 10px;
    margin-left: 5px;
  }
}

// Widget: Rss
.widget_rss{
  li {
    margin-bottom: 10px;
    .rsswidget {
      display: block;
      text-decoration: none;
      display: block;
      font-size: 15px;
      font-weight: bold;
    }

    .rss-date {
      display: block;
    }

    .rss-date, cite {
      font-size: 12px;
    }

    .rssSummary {
      font-size: 14px;
      display: inline;
    }
  }
}

// Widget: Post Tags
.post-tags,
.widget_tag_cloud {
  a {
    font-size: 0.938em;
    font-weight: 700;
    line-height: 1.1em;
    letter-spacing: 0.02em;
    display: inline-block;
    border: 1px solid transparent;
    padding: 0.286em 1em;
    margin: 0 .257em .557em 0;
    transition: all 500ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.117647) -1px 2px 6px, rgba(0, 0, 0, 0.117647) -1px 2px 4px;
    }
  }
}

// Widget: Card
.certy_widget_card {
  .text-muted {
    font-size: 1.143em;
  }
}

// Widget: Instagram
.certy_widget_instagram {
  max-width: 480px;
  margin-left: auto !important;
  margin-right: auto !important;

  li {
    width: 33.33%;
    height: 0;
    padding-top: 33.33%;
    position: relative;
    float: left;
  }

  a {
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    position: absolute;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &:hover {
      opacity: 0.7;
    }
  }
}