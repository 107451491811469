/* Post Single*/
.single-post {
  .post {
    margin-bottom: 0;
  }

  .post-footer {
    text-align: left;
  }

  .post-footer-top {
    padding-bottom: 1.15em;
  }

  .post-footer-btm {
    margin-top: 1.5em;
  }

  .post-media {
    margin-top: -20px;
  }
}

// Single Media
.post-video,
.post-vimeo,
.post-youtube,
.post-soundcloud,
.post-dailymotion {
  height: 0;
  padding-bottom: 50%;
  position: relative;

  iframe,
  video {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    position: absolute;
  }
}

.post-audio {
  audio {
    width: 100%;
  }
}

// Single Navigation
.post-nav {
  margin-top: 4.45em;

  &:last-child {
    .brd-btm {
      border: none;
    }

    .post-nav-next,
    .post-nav-prev {
      padding-bottom: 0;
    }
  }
}

.post-nav-prev,
.post-nav-next {
  padding-bottom: 1.750em;

  a {
    &:hover {
      opacity: 0.7;
    }
  }

  a, span, strong, figure {
    display: block;
  }

  span {
    font-size: 1.08em;
    font-weight: 700;
    letter-spacing: 0.11em;
    margin-bottom: 0.813em;
  }

  strong {
    font-size: 0.95em;
    line-height: 1.38em;
  }

  figure {
    margin-bottom: 0.9em;
  }

  img {
    display: block;
    width: 100%;
  }
}

@media (max-width: $screen-sm - 1) {
  .post-nav-prev,
  .post-nav-next {
    font-size: 0.8em;

    .text-left,
    .text-right {
      text-align: center;
    }
  }
}
