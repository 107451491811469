/**
 * Typography
 */

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  color: inherit;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  margin-bottom: $base-space;

  &:first-child {
    margin-top: 0;
  }
}

h1, .h1 {
  font-size: $font-size-h1;
  margin-top: $base-space * 2;
}

h2, .h2 {
  font-size: $font-size-h2;
  margin-top: $base-space * 1.8;
}

h3, .h3 {
  font-size: $font-size-h3;
  margin-top: $base-space * 1.4;
}

h4, .h4 {
  font-size: $font-size-h4;
  margin-top: $base-space * 1.2;
}

h5, .h5 {
  font-size: $font-size-h5;
  margin-top: $base-space * 1.1;
}

h6, .h6 {
  font-size: $font-size-h6;
  margin-top: $base-space * 1.2;
}

p {
  margin: 0 0 $base-space 0;
}

b,
strong {
  font-weight: 700;
}

i,
em,
dfn,
cite {
  font-style: italic;
}

blockquote {
  font-size: 2em;
  line-height: 1.2em;
  padding: 1.1em 1.9em;
  position: relative;
  border-width: 1px;
  border-style: solid;
  border-radius: 7px;
  width: 100%;
  max-width: 470px;
  margin: 0 auto $base-space;

  p:last-child {
    margin-bottom: 0;
  }

  cite {
    font-size: 0.433em;
    font-style: normal;
    line-height: 1.2em;
    display: block;
    margin-top: $base-space;
  }

  &.bg-primary {
    border-color: transparent;
  }

  &.quote-top {
    margin-top: 1em;
  }

  &.quote-top:before,
  &.quote-side:before {
    content: "\e90a";
    font-size: 0.7em;
    font-family: icomoon;
    line-height: 2.7em;
    text-align: center;
    width: 2.7em;
    height: 2.7em;
    border-radius: 50%;
    position: absolute;
    box-shadow: -0.25em 0.25em 1em rgba(118, 95, 91, 0.3);
  }

  &.quote-top:before {
    top: -1.7em;
    left: 50%;
    margin-left: -1.35em;
  }

  &.quote-side:before {
    top: 2.3em;
    left: -1.2em;
  }
}

address {
  font-style: italic;
  margin-bottom: $base-space;
}

code,
kbd,
tt,
var,
pre,
samp {
  font-size: 1em;
  font-family: monospace, monospace;
}

var {
  font-style: italic;
}

code {
  color: #c7254e;
  padding: 2px 4px;
  background-color: #f9f2f4;
  border-radius: 4px;
}

kbd {
  color: #fff;
  padding: 2px 4px;
  background-color: #333;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
}

pre {
  color: #333;
  font-size: 0.9em;
  line-height: 1.42857143;
  display: block;
  padding: 10px;
  margin-bottom: $base-space;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: auto;
  @include word-wrap();
}

abbr[title],
abbr[data-original-title] {
  cursor: help;
  border-bottom: 1px dotted #777;
}

mark {
  color: inherit;
  padding: 0 5px;
  text-decoration: none;
}

s,
strike,
del {
  text-decoration: line-through;
}

u,
ins {
  text-decoration: underline;
}

sup,
sub {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

small {
  font-size: 85%;
}

big {
  font-size: 125%;
}