/**
 * Page - 404
 */

.title-404 {
  display: block;
  line-height: 1.1;
  font-size: 10.063em;
  margin-bottom: 40px;
}

.info-404 {
  display: block;
  font-size: 1.500em;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 70px;
}