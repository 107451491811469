/**
 * Section: Contact
 */

@media(min-width:$screen-sm){
  .padd-box-xs{
    padding-left: 27%;
    padding-right: 17%;
  }
}

.contact-head{
  margin-bottom: 55px;
  .crt-social{
    margin-bottom: 45px;
  }
}

#map {
  width: 100%;
  padding-top: 80%;
  margin-top: 50px;

  // google sets light background for div, and this interferes with the dark versions
  & > div {
    background: transparent !important;
  }
}

@media (min-width: $screen-xs){
  #map {
    padding-top: 41%;
  }
}